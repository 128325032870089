import { App } from 'vue'
import * as Lib from './lib'
// import * as Handler from './handler'

const install = (app: App) => {
  // window.globalApp = app
  // lib, more like component register in global
  app.use(Lib)
  // global error and warn listener
  // app.use(Handler)
}

export {
  install
}
