<template>
  <div class="gift">
    <!-- <img
      src="/static/images/home/item-icon.png"
      @click="openDetail"
    > -->
    <img
      src="/static/images/home/item-icon-2.png"
      @click="openDetail"
    >
  </div>
</template>
<script setup lang="ts">
const apiPrefix = import.meta.env.VITE_ENV === 'prod' ? '/yuhangplatform' : ''

function openDetail() {
  // window.open(apiPrefix + '/resource-recommend/#/recommend?id=1')
  window.open(apiPrefix + '/ysx-interest-subsidy-com/index.html')
}
</script>

<style scoped lang="scss">
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(6deg);
  }
  20% {
    transform: rotate(-6deg);
  }
  30% {
    transform: rotate(6deg);
  }
  40% {
    transform: rotate(-6deg);
  }
  50% {
    transform: rotate(6deg);
  }
  60%,
  100% {
    transform: rotate(0deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.gift {
  position: fixed;
  right: 12px;
  bottom: 20%;
  z-index: 999;
  animation: scale 2s infinite;

  img {
    cursor: pointer;
    // width: 170px;
    // height: 78px;
    width: 190px;
    height: 88px;
    animation: shake 2s infinite;
  }
}
</style>
