/**
 * qinqing apis
 */

import request from '../request'
import { ApiProxy } from '@/config'
import { PolicyCollectParam } from '../types/java'
import { PromiseRo } from '@/typings/params'

const prefixQQ = ApiProxy.java.qinqing
const main = ApiProxy.java.main

// 亲清部门
export function qqDepart (data?: any): PromiseRo<any> {
  return request({
    url: prefixQQ + '/pc/policy/info/listPublishPolicyDepart',
    type: 'GET',
    params: data
  })
}

// 亲清政策收藏
export function qqPolicyCollect (data: PolicyCollectParam): PromiseRo<string> {
  return request({
    url: prefixQQ + '/h5/company/collect/policy/collectOrCancel',
    type: 'POST',
    params: data
  })
}

export function getMessageRecordCountResult (params: any = {}): PromiseRo {
  return request({
    url: main + '/pc/yhqq2/message/record/getMessageRecordCountResult',
    params,
    type: 'POST'
  })
}

export function getMessageRecordList (params: any): PromiseRo {
  return request({
    url: main + '/pc/yhqq2/message/record/getMessageRecordList',
    params,
    type: 'POST'
  })
}

export function getYhqq2MessageRecordIdResult (params: any): PromiseRo {
  return request({
    url: main + '/pc/yhqq2/message/record/getYhqq2MessageRecordIdResult',
    params,
    type: 'POST'
  })
}

export function setAllRead (params: any): PromiseRo {
  return request({
    url: main + '/pc/yhqq2/message/record/setAllRead',
    params,
    type: 'POST'
  })
}

export function qqSetRead (params: any): PromiseRo {
  return request({
    url: main + '/pc/yhqq2/message/record/setRead',
    params,
    type: 'POST'
  })
}

export function getCompanyApplyList (data: any): PromiseRo {
  return request({
    url: prefixQQ + '/pc/company/center/listPolicyApplyInfo',
    params: data,
    type: 'POST'
  })
}

export function getCompanyApplyListCount (data: any): PromiseRo {
  return request({
    url: prefixQQ + '/pc/company/center/groupCountPolicyApplyInfo',
    params: data,
    type: 'POST'
  })
}
export function getMyCollectPolicy (data: any): PromiseRo {
  return request({
    url: prefixQQ + '/h5/company/collect/policy/listMyCollect',
    params: data,
    type: 'POST'
  })
}
export function addNewsNoticeVisitCount (data: any): PromiseRo {
  return request({
    url: prefixQQ + '/pc/news/notice/addVisit',
    params: data,
    type: 'POST'
  })
}
