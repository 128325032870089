import request from '@/api/request'
import {
  install,
  GroupContent,

  UiForm,
  UiTable,
  UiPagination,
  UiBreadcrumb,
  UiGroupBtn
} from 'static/lib/entry'

import { App } from 'vue'

import 'static/lib/theme/entry.css'
import { ApiProxy, apiPrefix } from '@/config'
import store from '@/store'
import router from '@/router'

export default {
  install (app: App) {
    install({
      async: { request, proxy: { java: apiPrefix, node: ApiProxy.node.main } },
      router,
      store,
      app
    } as any)
    app.component('GroupContent', GroupContent)
    app.component('UiForm', UiForm)
    app.component('UiTable', UiTable)
    app.component('UiPagination', UiPagination)
    app.component('UiBreadcrumb', UiBreadcrumb)
    app.component('UiGroupBtn', UiGroupBtn)
  }
}
