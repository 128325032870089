import { createApp } from 'vue'
import App from './App.vue'
import * as global from './global'
import './router/permission'
import router from './router'
import store from './store'
import microApp from '@micro-zoe/micro-app'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import '@/utils/zlb'
microApp.router.setBaseAppRouter(router)
microApp.start()
import '@/assets/styles/index.scss'
import 'animate.css'
// import 'amfe-flexible'

const { VITE_JAVA_SOURCE } = import.meta.env
SZZT.init({
  prefix: VITE_JAVA_SOURCE,
  router: router,
  appKey: 'YSX_COMPANY_PC',
  user: {
    session: sessionStorage,
    userKey: 'user',
    idKey: 'id'
  },
  accessSource: '企业服务侧'
})
const app = createApp(App)
app.use(VueDOMPurifyHTML)
app.use(router)
app.use(store)
app.use(global)

app.mount('#app')
