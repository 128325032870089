import Cookie from 'js-cookie'

interface API {
  appId: string
  userType: number
  login: string
  logout: string
  logoutApi: string
}

interface GlobalConfig {
  1: API
  2: API
}

enum Type {
  person = 1,
  legal = 2,
}

interface Person {
  type: Type.person
}

interface Legal {
  type: Type.legal
}

function addQueryParams (href: string, key: string, value: string) {
  const params = href.split('?')[1]
  if (params) {
    const paramsArr = params.split('&')
    const paramsObj: any = {}
    paramsArr.forEach(item => {
      const keyVal = item.split('=')
      paramsObj[keyVal[0]] = keyVal[1]
    })
    paramsObj[key] = value
    let paramsStr = ''
    for (const key in paramsObj) {
      paramsStr += `${ key }=${ paramsObj[key] }&`
    }
    return `${ href.split('?')[0] }?${ paramsStr.slice(0, -1) }`
  } else {
    return `${ href }?${ key }=${ value }`
  }
}

(function (global) {
  class ZLB {
    global: GlobalConfig = {
      1: {
        userType: 1,
        appId: 'A330111252126202105001178_1',
        login: 'https://portal.zjzwfw.gov.cn/uc/sso/login',
        logout: 'https://portal.zjzwfw.gov.cn/uc/unifiedLogout',
        logoutApi: '/bj_bhb_person_api/pc/zlb/v2/login/loginout'
      },
      2: {
        userType: 2,
        appId: 'A330111252126202105001178_2',
        login: 'https://portal.zjzwfw.gov.cn/uc/sso/login',
        logout: 'https://portal.zjzwfw.gov.cn/uc/unifiedLogout',
        logoutApi: '/bj_bhb_com_api/pc/zlb/v2/login/loginout'
      }
    }
    use: API = {} as API

    setUse (params: Person | Legal) {
      this.use = this.global[params.type]
    }

    isLogin () {
      return !!Cookie.get('ysxUserId')
    }

    getTicket (params: Person | Legal) {
      this.setUse(params)
      const type = params.type === 1 ? 'person' : 'legal'
      if (type === 'person') {
        global.ysxHeader.personLogin()
      } else {
        global.ysxHeader.spsaehandlerLogin()
      }
    }

    async fetchLogout () {
      try {
        const { logoutApi } = this.use
        return await fetch(logoutApi, { method: 'post', headers: { 'Content-Type': 'application/json' } }).then(response => response.json())
      } catch (error) {
        console.log(error)
      }
    }

    async logout () {
      global.ysxHeader.doLogout()
    }

    clearLoginInfo () {
      sessionStorage.clear()
    }
  }

  const userType = Cookie.get('userType') || Cookie.get('ysxUserType')
  global.zlb = new ZLB()
  if (userType) {
    global.zlb.setUse({
      type: Number(userType)
    })
  }
})(window)
