/**
 * java common apis
 */

import { PromiseRo } from '@/typings/params'
import request from '../request'
import { ApiProxy } from '@/config'

const prefix = ApiProxy.java.main
const prefixQQ = ApiProxy.java.qinqing

// 单个查询 如：type: 1
export function jSingleChoose (data: any) {
  return request({
    url: prefix + '/pc/ads/common/choose/getLevelTreeChooseResult',
    type: 'POST',
    params: data || {}
  })
}

// 多个查询 如：typeArray: [1, 2]; 注意：type = 1 用jSingleChoose 接口
export function jMultipleChoose (data: any) {
  return request({
    url: prefix + '/pc/ads/common/choose/getTreeChooseAllListResult',
    type: 'POST',
    params: data || {}
  })
}

// 多个查询 如：typeArray: [1, 2]; 注意：type = 1 用jSingleChoose 接口
export function fileUpload (data?: any) {
  return request({
    url: prefix + '/pc/upload/file',
    type: 'POST',
    params: data || {}
  })
}

// 获取北京时间
export function jGetTimestamp (): PromiseRo<number> {
  return request({
    url: prefix + '/ysx/home/getNowTime',
    type: 'POST',
    params: {}
  })
}

// 上传
export function jUpload (params: any) {
  return request({
    url: prefixQQ + `/pc/upload/${import.meta.env.VITE_ENV === 'prod' ? 'ossFile' : 'file'}`,
    params,
    type: 'POST'
  }, {
    'Content-Type': 'multipart/form-data'
  })
}
// 资讯公告
export function jNoticeList (params: any) {
  return request({
    url: prefixQQ + '/pc/news/notice/getNewsNoticeListResult',
    type: 'POST',
    params
  })
}
// 政策统计
export function jPolicyList (params: any) {
  return request({
    url: prefixQQ + '/pc/policy/info/getDirectPolicys',
    type: 'POST',
    params
  })
}
// 个人中心消息、政策、诉求统计
export function jCompanyTypeCount (params: any) {
  return request({
    url: prefix + '/ysx/center/index/getLoginInfo',
    type: 'POST',
    params
  })
}
// 个人中心政策推荐
export function jCompanyPolicyRecommand (params: any) {
  return request({
    url: prefix + '/ysx/center/index/resourcePolicyRecommend',
    type: 'POST',
    params
  })
}
// 个人中心空间推荐
export function jCompanySpaceRecommand (params: any) {
  return request({
    url: prefix + '/ysx/center/index/roomRecommend',
    type: 'POST',
    params
  })
}
// 个人中心资讯推荐
export function jCompanyNoticeRecommand (params: any) {
  return request({
    url: prefix + '/ysx/home/recentNews',
    type: 'POST',
    params
  })
}