<template>
  <section class="login-view theme-dialog">
    <el-dialog
      v-model="show"
      title="温馨提醒"
      width="400px"
      @close="onClose"
    >
      <section class="lvc">
        <span v-html="options.tip"></span>
      </section>

      <template #footer>
        <section class="lvo">
          <el-button @click="onClickCancel">取消</el-button>
          <el-button
            type="primary"
            @click="onClickLogin"
          >
            去登录
          </el-button>
        </section>
      </template>
    </el-dialog>
  </section>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'
import { useSubscribe } from 'static/lib/hooks'
import { loginDialog } from '@/global/pubsub'
import { LDOpenParam, LDOptions } from '@/typings/global'
import { metaFilePath } from '@/utils'

// hooks
const router = useRouter()

// state
const bg = ref<string>(`url(${ metaFilePath('dialog-b1.png') }) no-repeat`)
const show = ref(false)
const options = ref<LDOptions>({
  tip: '',
  userType: 1,
  userTypeName: '个人',
  resolve: null as any
})

// fns
function onClickCancel () {
  show.value = false
}

function onClickLogin () {
  window.ysxHeader[
    options.value.userType === 1 ? 'personLogin' : 'spsaehandlerLogin'
  ]()
  cleanOptions()
}

function onClose () {
  const { reject, userTypeName: utn } = options.value
  if (!!reject) {
    reject({ success: false, message: `${ utn }登陆取消` })
  }
  doBack()
  cleanOptions()
}

function doBack () {
  const { back } = options.value
  if (back !== false)
    router.push(back === true ? '/' : (back as RouteLocationRaw))
}

function cleanOptions () {
  show.value = false
  options.value = {
    tip: '',
    userType: 1,
    userTypeName: '个人',
    back: undefined,
    resolve: null as any,
    reject: null as any
  }
}

// subscribe
useSubscribe(loginDialog.OPEN).subscribe((opts: LDOpenParam) => {
  const { userType: ut, back, resolve, reject } = opts || {}
  options.value.userType = ut
  options.value.userTypeName = ut === 1 ? '个人' : '法人'
  options.value.tip = `您好，您访问的是<span class="theme-color" style="margin-top: 20px;">${ options.value.userTypeName }</span>相关应用/功能，请登录${ options.value.userTypeName }账户继续操作。`
  options.value.resolve = resolve
  options.value.reject = reject
  options.value.back = back
  show.value = true
  switch (true) {
    case ut === 1:
      break
    case ut === 2:
      break
    default:
      break
  }
})
</script>
<style lang="scss" scoped>
.login-view {
  :deep() {
    .el-dialog {
      height: 240px;
      display: flex;
      flex-direction: column;
      background: v-bind(bg);
      background-size: 100% 100%;

      .el-dialog__header {
        display: flex;
        align-items: center;
        justify-content: center;

        .el-dialog__title {
          font-family: DingTalkJinBuTi;
          font-size: 20px;
        }
      }

      .el-dialog__body {
        flex: 1;
      }
    }
  }

  .lvc {
    line-height: 1.8;
    font-size: 16px;
    color: $primary-color-title;
  }

  .lvo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
