'use strict'
import axios from 'axios'
import { cloneDeep } from 'lodash-es'
import router from '@/router'
import { ElMessage } from 'element-plus'

// init
const service = axios.create({
  timeout: 9000
})
// request
service.interceptors.request.use(
  config => config,
  error => {
    console.log(error)
    Promise.reject(error)
  }
)
// response
service.interceptors.response.use(
  response => {
    const json = response && response.data
    if (json instanceof Object) {
      // 如果未登录 后台返回登录页字符串
      if (json.status === 10001) {
        ElMessage.closeAll()
        router.push('/home')
      }
      json.response = cloneDeep(response)
    }

    return json
  },
  error => {
    console.log('网络异常: ', error)
    // ElMessage.closeAll()
    // ElMessage.info('网络异常')
    return Promise.resolve({ error })
  }
)

// ajax entry
const suffix = '' // 后缀
const ajax = function (obj: any, config?: any) {
  const _suffix = obj.suffix !== undefined ? obj.suffix : suffix
  const url = obj.url + _suffix
  const type = obj.type ? obj.type.toUpperCase() : 'GET'
  const uConfig = Object.assign({}, config || {})
  const params = obj.params

  switch (type) {
    case 'POST':
      return service.post(url, params, { ...uConfig })
    case 'PUT':
      return service.put(url, params, { ...uConfig })
    case 'DELETE':
      return service.delete(url, { ...uConfig })
    default:
      return service.get(url, { params: params, ...uConfig })
      break
  }
}

export default ajax
