import { App } from 'vue'
import ElementPlus from './element-plus'
import ZtPlus from './zt'
// import chart from './chart'

const install = (app: App): void => {
  app.use(ElementPlus)
  app.use(ZtPlus)
  // app.use(chart)
}

export {
  install
}
