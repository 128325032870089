export const constantRoutes: Array<any> = [
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/error/401.vue'),
    meta: { permission: false }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error/404.vue'),
    meta: { permission: false }
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/error/500.vue'),
    meta: { permission: false }
  },
  {
    path: '/example',
    name: 'Example',
    component: () => import('@/views/common/example.vue'),
    meta: { permission: false }
  },
  {
    path: '/e-token',
    name: 'EToken',
    component: () => import('@/views/common/token.vue'),
    meta: { permission: false }
  },
  {
    path: '/resource-recommend',
    name: '增值化改革“一类事”',
    component: () => import('@/views/micro/resource-recommend.vue'),
    meta: { permission: false }
  },
  {
    path: '/micro-activity',
    name: '找活动',
    component: () => import('@/views/micro/activity.vue'),
    meta: { permission: false, showHeader: true }
  }
]
