import { ResponseMode } from '@/typings/params'
import store from '../../store'
import Cookies from 'js-cookie'
import { RouteLocationNormalized } from 'vue-router'

export async function syncIng (to: RouteLocationNormalized) {
  initBusinessData()
  const { success }: ResponseMode = await doAuth()
  if (to.meta.permission === true && !success) throw Error('授权失败')
}

/**
 * 初始化业务数据
 */
export async function initBusinessData () {
  // 字典值
  const { isLoadedDict } = store.getters || {}
  if (!isLoadedDict) {
    await store.dispatch('initDict')
  }
}

/**
 * 认证
 * @param to
 */
export async function doAuth (): Promise<any> {
  const isLogin = window.ysxHeader.isLogin()
  let result: ResponseMode = { success: isLogin, data: '' }
  try {
    const { userId } = store.getters || {}
    if (isLogin === true && String(userId) !== String(Cookies.get('ysxUserId'))) {
      result = await store.dispatch('getUserInfo')
    }
    return result
  } catch (error) {
    console.log(error)
    return result
  }
}
