import { uDingLogin, uLogin, uLogout, uTokenLogin, vTokenLogin, vUserCurrent } from '@/api'
import { DingLoginMode, LoginMode, ResponseMode } from '@/typings/params'
import store from '..'
import { ElMessage } from 'element-plus'
import { ActionContext } from 'vuex'
import { LoginStoreMode } from '@/typings/store'
import Cookie from 'js-cookie'

export interface LoginRedirect {
  path?: string
  type?: string
  query?: any
}

const app = {
  state: () => ({
    system: {},
    loginInfo: {
      token: sessionStorage.getItem('SZ_ZS_TOKEN'),
    } as LoginStoreMode,
    redirect: {
      path: '',
      type: '',
      query: {}
    } as LoginRedirect
  }),

  getters: {
    loginInfo: (state: any): any => state.loginInfo,
    redirect: (state: any): any => state.redirect
  },

  mutations: {
    SET_LOGIN_INFO (state: any, data: any) {
      const { token } = data || {}
      const useToken = Cookie.get('SZ_ZS_TOKEN') || (token || '')

      state.loginInfo.token = useToken
      sessionStorage.setItem('SZ_ZS_TOKEN', useToken)
    },

    CLEAR_LOGIN_INFO (state: any) {
      state.loginInfo = {}
      Cookie.set('SZ_ZS_TOKEN', '')
      sessionStorage.setItem('SZ_ZS_TOKEN', '')
    },

    SET_LOGIN_REDIRECT (state: any, data: any) {
      state.redirect = data || {
        path: '',
        type: '',
        query: {}
      }
    }
  },

  actions: {
    // 账号密码登录
    async login ({ commit }: ActionContext<LoginStoreMode, any>, params: LoginMode) {
      try {
        let result: any
        const { data, success, errMsg }: ResponseMode = await uLogin(params)
        if (!success) {
          result = { success, data }
          ElMessage.error(errMsg)
        } else {
          // result = await store.dispatch('getUserInfo')
          result = Object.assign({
            success
          }, params || {}, data || {})
          commit('SET_LOGIN_INFO', result)
        }
        return result
      } catch (error) {
        console.log(error)
      }
    },

    // login by token
    async tokenLogin ({ commit }: any, token: string) {
      try {
        let result: any
        const { data, success, errMsg }: any = await uTokenLogin({ token }) || {}
        if (!success) {
          result = { success, data }
          ElMessage.error(errMsg)
        } else {
          commit('SET_LOGIN_INFO', data)
          result = await store.dispatch('getUserInfo')
        }
        return result
      } catch (error) {
        console.log(error)
      }
    },

    // 浙政钉扫码登录
    async dingLogin ({ commit }:any, params: DingLoginMode) {
      try {
        let result: any
        const { data, success, errMsg } = await uDingLogin(params)
        if (!success || !data) {
          result = { success, data }
          ElMessage.error(errMsg)
        } else {
          commit('SET_LOGIN_INFO', data)
          result = await store.dispatch('getUserInfo')
        }
        return result
      } catch (error) {
        return error
      }
    },

    // 退出
    async logout ({ commit }: any) {
      try {
        const { success, errMsg }: ResponseMode = await uLogout()
        if (success) {
          commit('CLEAR_LOGIN_INFO')
          await store.dispatch('clearUserStore')
          await store.dispatch('removeMenus')
          await store.dispatch('removeRoutes')
          ElMessage.success('退出成功。')
          await store.dispatch('clearUserStore')
        } else {
          ElMessage.error(errMsg)
        }
        return success
      } catch (error) {
        console.log(error)
      }
    },

    async setLoginRedirect ({ commit }: any, data:any) {
      try {
        commit('SET_LOGIN_REDIRECT', data)
      } catch (error) {
        console.log(error)
      }
    },

    async vtokenLogin ({ commit }: any, token: string) {
      try {
        let result: any
        const { data, errMsg }: any = await vTokenLogin({ token }) || {}
        if (data.token) {
          commit('SET_LOGIN_INFO', data)
          window.sessionStorage.setItem('token-zs', data.token)
        } else {
          result = { success: false, data }
          ElMessage.error(errMsg)
        }
        return result
      } catch (error) {
        console.log(error)
      }
    },
    async vCurrent ({ commit }: any) {
      try {
        let result: any
        const { data, success, errMsg }: any = await vUserCurrent() || {}
        if (!success) {
          result = { success, data }
          ElMessage.error(errMsg)
        } else {
          commit('SET_LOGIN_INFO', data)
          // result = await store.dispatch('getUserInfo')
        }
        return result
      } catch (error) {
        console.log(error)
      }
    },
  }
}
export default app
