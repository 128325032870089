import { RouteMeta, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import router from '..'
import { setTitle } from './util'
import { nextReplace, doNext, addRoutes, getAppInfo } from './state'
import { syncIng } from './async'
import { ElMessage } from 'element-plus'
import { enquireLogin } from '@/utils'

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const meta: RouteMeta = to.meta
  try {
    setTitle(meta)
    getAppInfo()
    const { sessionId, realName, type } = to.query || {}
    if (!!sessionId || (!!realName && !!type)) {
      delete to.query.sessionId
      delete to.query.realName
      delete to.query.type
      return next(to)
    }
    const { router: defaultTo } = await addRoutes()

    if (meta.permission === false) return next()

    await syncIng(to)

    if (defaultTo) return nextReplace(to, next)

    doNext(to, from, next)
  } catch (error) {
    console.log(error)
    ElMessage.closeAll()
    validateError(to, from, next)
  }
})

async function validateError (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  try {
    if (from.path === '/') return next('/home')
    await enquireLogin({ userType: 2, back: false })
    next()
  } catch (error) {
    console.log(error)
  }
}
