import { jSingleChoose, jMultipleChoose, nEnumList } from '@/api'
import { ResponseMode } from '@/typings/params'
import Config from '@/config'
import { get } from 'lodash-es'
import { transformToTree } from '@/utils'

export interface DictMode {
  id: number
  name: string
  parentId: number
  type: string | number
  childList?: DictMode[]
  path?: string
  valueRange?: string
}

interface DictListMode {
  levelList: DictMode[]
  resultList: DictMode[]
  type: number
}

// 创建dict key
export function createDictKey (type: number | string) {
  return `dict_${type}`
}

const dictApis = [
  {
    api: jMultipleChoose,
    type: 'java',
    params: {
      typeArray: [258, 259,260,261,262,263,264] as number[]
    }
  },

  {
    api: nEnumList,
    type: 'node',
    params: Config.project.id
  }
]

const app = {
  state: () => ({
    isLoadedDict: false,
    dictData: {}
  }),

  getters: {
    isLoadedDict: (state: any) => state.isLoadedDict,
    dictData: (state: any): any =>
      Object.keys(state.dictData).length > 1
        ? state.dictData
        : JSON.parse(sessionStorage.getItem('dictData') || '{}')
  },

  mutations: {
    SET_DICT_DATA (state: any, data: any) {
      state.isLoadedDict = true
      state.dictData = data || {}
      sessionStorage.setItem('dictData', JSON.stringify(state.dictData))
    }
  },

  actions: {
    /**
     * 初始化多个字典值
     * @param param0
     * @returns
     */
    async initDict ({ commit, state }: any) {
      try {
        const datas = await Promise.all(
          dictApis.map((item: any) => item.api(item.params).catch((err: any) => err))
        )
        const dictData = state.dictData || {}
        datas.map((data: any, index: number) => {
          switch (index) {
            case 0:
              if (data.success) {
                const list: DictListMode[] = get(data, 'data.listResultList', [])
                list.map((item: DictListMode) => {
                  if (item.type) {
                    const useType = createDictKey(item.type)
                    dictData[useType] = {
                      tree: item.resultList || [],
                      list: item.levelList || []
                    }
                  }
                })
              }
              break
            case 1:
              const list = get(data, 'data', []).map((o: any) => {
                ['true', 'false'].includes(o.value) && (o.value = JSON.parse(o.value))
                return o
              })
              const treeData = transformToTree(list)
              treeData.map((item: any) => {
                const useType = createDictKey(`n${item.id}`)
                dictData[useType] = {
                  tree: item.children,
                  list: list.filter((o: any) => o.parentId === item.id)
                }
              })
              break
          }
        })
        commit('SET_DICT_DATA', dictData)
      } catch (error) {
        console.log(error)
      }
    },

    /**
     * 获取单个字典值
     * @param param0
     * @param type
     * @returns
     */
    async getSingleChoose ({ commit, state }: any, type: number) {
      try {
        if (!type && type !== 0) return
        const useType = createDictKey(type)
        const dictData = state.dictData || {}
        if (dictData[useType]) {
          return dictData[useType]
        }

        const { data, success }: ResponseMode = await jSingleChoose({ type })
        if (!success) return data
        const jsonData: DictListMode = data || {}
        const itemData = {
          tree: jsonData.resultList || [],
          list: jsonData.levelList || []
        }
        dictData[useType] = itemData
        commit('SET_DICT_DATA', dictData)
        return itemData
      } catch (error) {
        console.log(error)
      }
    }
  }
}
export default app

function getText () {
  const items = document.querySelectorAll('.line-item')
  items.forEach(o => {
    // getResult(o.innerHTML || '')
    console.log(o.innerHTML)
  })
}
function getResult (texts) {
  const labels = ['研发人员数', '总利润', '纳税额增速', '净利润', '研发费用增速', '上市阶段', '上市板块', '核算方式', '专利数量', '企业名称', '企业规模', '实缴注册资本', '上市地点', '购买设备金额', '纳税额', '研发费用', '研发投入占比', '挂牌地点', '企业类型', '注册地点', '法人资格', '经营时间', '增值税', '营业收入增长率', '实缴增值税', '专利类型', '员工总数', '成立时长', '所属产业', '企业标签', '研发人员占比', '成立时间', '募集金额', '经营范围', '交易额', '经营时长', '固定资产', '营业收入', '员工数量', '实收资本', '企业所得税', '销售额', '企业经营时长', '研发投入', '企业获奖名称', '企业获奖级别', '企业获奖时间', '雇佣人数', '发明专利数量', '注册资本(万元)', '注册资本', '行业分类', '企业资质名称', '企业资质等级', '企业资质时间', '融资金额', '奖励金额']
  const result = []

  labels.map(item => {
    // item.split('').map(o => {
    //   texts.indexOf(o) > -1 && result.push(o)
    // })
    texts.indexOf(item) > -1 && result.push(item)
  })

  result.map(o => {
    console.log(o)
  })

  return result
}

