import { uUserInfo, uUserInfoPerson } from '@/api'
import { ResponseMode } from '@/typings/params'
import { ActionContext } from 'vuex'
import { UserInfoMode, UserStoreMode } from '@/typings/store'
import Cookie from 'js-cookie'
import { UserType } from '@/api/types/java'

const userInfoApis: any = {
  1: uUserInfoPerson,
  2: uUserInfo
}
const user = {
  state: () => ({
    user: JSON.parse(sessionStorage.getItem('user') || '{}'),
    role: JSON.parse(sessionStorage.getItem('role') || '{}'),
    isLogin: !!Cookie.get('ysxUserId'),
    username: '',
    userId: ''
  }),

  getters: {
    user: (state: any) => state.user,
    userId: (state: any): string | number => state.userId,
    username: (state: any): string => state.username,
    isLogin: () => !!Cookie.get('ysxUserId'),
    role: (state: any) => state.role,
    userType: (): UserType => Number(Cookie.get('ysxUserType')) as UserType,
    roleId: (): number | undefined => {
      const ut = Number(Cookie.get('ysxUserType'))
      return ut === 1 ? 356 : (ut === 2 ? 355 : undefined)
    },
  },

  mutations: {
    SET_USER (state: any, data: UserInfoMode) {
      state.user = data
      state.userId = data.ysxUserId
      state.username = data.companyName
      if (Number(Cookie.get('ysxUserType')) === 1) {
        state.username = data.username
      }
      state.user.name = state.username
      state.isLogin = true
      sessionStorage.setItem('user', JSON.stringify(data))
    },

    SET_USER_ROLE (state: any, data: any) {
      state.role = data
      sessionStorage.setItem('role', JSON.stringify(data))
    },

    CLEAR_USER_STORE (state: any) {
      state.user = {}
      state.isLogin = false
      state.userId = ''
      state.username = ''
      sessionStorage.setItem('user', '')
      sessionStorage.setItem('role', '')
    }
  },

  actions: {
    async getUserInfo ({ commit }: ActionContext<UserStoreMode, any>) {
      try {
        const ut = Number(Cookie.get('ysxUserType'))

        const { data, success }: ResponseMode<any> = await userInfoApis[ut]()
        commit('SET_USER', data || {})
        return { data, success }
      } catch (error) {
        console.log(error)
      }
    },

    setUserRole ({ commit }: any, data: any) {
      commit('SET_USER_ROLE', data)
    },

    /**
     * 清除
     */
    clearUserStore ({ commit }: ActionContext<string, any>) {
      commit('CLEAR_USER_STORE')
    }
  }
}
export default user
