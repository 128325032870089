/**
 * node menu apis
 */

import { DingLoginMode, LoginMode, PromiseRo } from '@/typings/params'
import request from '../request'
import { ApiProxy } from '@/config'
import { UserUndoCount } from '../types/java'

const prefix = ApiProxy.java.main
const qinqing = ApiProxy.java.qinqing
const prefixSummer = ApiProxy.java.summer

// 账号密码登录
export function uLogin (data: LoginMode): Promise<any> {
  return request({
    url: prefix + '/pc/login/login',
    type: 'POST',
    params: data || {
    }
  })
}

// 退出登录
export function uLogout (): Promise<any> {
  return request({
    url: prefix + '/pc/login/loginout',
    type: 'POST'
  })
}

// 登录信息
export function uUserInfo (): Promise<any> {
  return request({
    url: qinqing + '/pc/user/login/getloginresult',
    type: 'POST'
  })
}

// 个人用户信息
export function uUserInfoPerson (): Promise<any> {
  return request({
    url: prefixSummer + '/pc/pers/zw/user/getPersZwUserIdResult',
    type: 'POST'
  })
}

// 用户业务统计
export function uUserBCount (): PromiseRo<UserUndoCount> {
  return request({
    url: prefix + '/ysx/center/index/getLoginInfo',
    type: 'POST'
  })
}

// login by token
export function uTokenLogin (data?: any): Promise<any> {
  return request({
    url: prefix + '/pc/login/loginbytoken',
    type: 'POST',
    params: data || {}
  })
}

// 获取ding scan用户信息
export function uDingLogin (data?: DingLoginMode): Promise<any> {
  return request({
    url: prefix + '/pc/zzd/login/getZzdLoginResult',
    type: 'POST',
    params: data || {}
  })
}

export function vTokenLogin (data?: any): Promise<any> {
  return request({
    url: '/fyzs' + '/user/login',
    type: 'get',
    params: data || {}
  })
}
export function vUserCurrent (data?: any): Promise<any> {
  return request({
    url: '/fyzs' + '/user/current',
    type: 'Get',
    params: data || {}
  })
}
