<template>
  <section class="submenuBox">
    <el-sub-menu :index="menu.routerPath">
      <template #title>
        <span class="submenu-title">
          <i v-if="menu.icon" :class="['iconfont', menu.icon]"></i>
          <span>{{ menu.name }}</span>
        </span>
      </template>
      <template v-for="item in menu.children" :key="item.id">
        <template v-if="item.hidden !== 0">
          <!-- 嵌套 -->
          <template v-if="Number(item.menuType) === 1 && item.children && item.children.length > 0">
            <sub-menu
              :key="item.id"
              :index="menu.routerPath"
              :menu="item"
            />
          </template>
          <template v-else>
            <el-menu-item
              :index="item.routerPath"
              @click="onMenu(item)"
            >
              <span>{{ item.name }}</span>
            </el-menu-item>
          </template>
        </template>
      </template>
    </el-sub-menu>
  </section>
</template>
<script lang="ts" setup>
import { open } from '@/utils'
import { MenuMode } from '@/typings/data'

const props = defineProps({
  // 菜单
  menu: { type: Object, default: () => ({}) },
  index: { type: [String, Number], default: '' },
  emit: { type: Boolean, default: false }
})

const emits = defineEmits(['clickMenu'])

function onMenu (menu: MenuMode) {
  if (props.emit) return emits('clickMenu', menu)
  open(menu)
}
</script>
<style lang="scss" scoped>
.submenu-title {
  i {
    display: inline-block;
    width: 22px;
    text-align: center;
    margin-right: 6px;
    font-weight: 600;
    color: $primary-color;
  }
}
</style>
